<template>
  <el-dialog
    :visible.sync="dialogVisible"
    title="合同详情"
    :before-close="handleClose"
    width="60%"
  >
    <div class="sys_contract_tpl">
      <table id="print-table">
        <tr>
          <td colspan="2">供方</td>
          <td colspan="2">需方</td>
        </tr>
        <tr>
          <td>单位名称</td>
          <td>{{ supplierInfo.name }}</td>
          <td>单位名称</td>
          <td>{{ buyerInfo.name }}</td>
        </tr>
        <tr>
          <td>单位地址</td>
          <td>{{ supplierInfo.detailAddress }}</td>
          <td>单位地址</td>
          <td>{{ buyerInfo.address }}</td>
        </tr>
        <tr>
          <td>法人代表</td>
          <td>{{ supplierInfo.owner }}</td>
          <td>法人代表</td>
          <td>{{ buyerInfo.owner }}</td>
        </tr>
        <tr>
          <td>电话传真</td>
          <td>{{ supplierInfo.fax || supplierInfo.telephone }}</td>
          <td>电话传真</td>
          <td>{{ buyerInfo.tax }}</td>
        </tr>
        <tr>
          <td>邮编</td>
          <td>{{ supplierInfo.postcode }}</td>
          <td>邮编</td>
          <td>{{ buyerInfo.postCode }}</td>
        </tr>
      </table>

      <p>
        甲乙双方经过平等协商，达成本备件采购合同（以下简称“合同”），合同内容包括具体条款、附件及合同中提及的其他文件
      </p>
      <h4>
        1.产品名称和价款（具体采购标的可附附件，附件是合同的有机组成部分，具有补充、解释或修改合同的作用，对双方有约束力）
      </h4>
      <vxe-table
        border
        resizable
        show-header-overflow
        highlight-hover-row
        :data="materialList"
      >
        <vxe-table-column field="goodsCode" title="商品编码"></vxe-table-column>
        <vxe-table-column field="goodsName" title="商品名称"></vxe-table-column>
        <vxe-table-column field="specsNo" title="规格型号"></vxe-table-column>
        <vxe-table-column field="brandName" title="品牌名称"></vxe-table-column>
        <vxe-table-column field="producer" title="生产商"></vxe-table-column>
        <vxe-table-column
          field="isMedichair"
          title="是否医疗件"
        ></vxe-table-column>
        <vxe-table-column
          field="unitOfMsment"
          title="计量单位"
        ></vxe-table-column>
        <vxe-table-column field="unitPrice" title="含税单价"></vxe-table-column>
        <vxe-table-column field="orderNum" title="数量"></vxe-table-column>
        <vxe-table-column field="address" title="总价">
          <template #default="{ row }">
            {{ (row.orderNum * row.unitPrice).toFixed(2) }}
          </template>
        </vxe-table-column>
      </vxe-table>
      <h4>2.合同总价款</h4>
      <p>
        总价（人民币）： {{ totalAmount }} 元；（大写）：
        {{ numberToChinese(totalAmount) }} 元
      </p>
      <p>上述价款含运费、保险费</p>
      <p>
        以上价款为 {{ data.isTax === 1 ? "是" : "否" }} （是/否）含税，税率为
        {{ data.taxRate }} .
      </p>
      <h4>3.付款方式及发票开具：</h4>
      <p>
        3.1 甲方按照如下约定分
        {{ data.stagesList ? data.stagesList.length : 0 }} 期向乙方支付合同款项
      </p>
      <div v-if="data.stagesList && data.stagesList[0]">
        <template v-for="(s, i) in data.stagesList">
          <p
            :key="i"
            v-if="
              data.stagesList.length >= 2 &&
                i >= 1 &&
                i === data.stagesList.length - 1
            "
          >
            第{{
              i + 1
            }}期：作为质量保证金，在双方约定的保修期届满后若乙方已全部完成项下全部义务，则甲方在{{
              data.stagesList[data.stagesList.length - 1].payDate
            }}
            日内向乙方付清人民币{{
              data.stagesList[data.stagesList.length - 1].payAmount
            }}
            元；
          </p>
          <p v-else :key="i">
            第{{ i + 1 }}期：在{{
              data.stagesList && data.stagesList[0]
                ? data.stagesList[0].payDate
                : ""
            }}前付清人民币{{
              data.stagesList && data.stagesList[0]
                ? data.stagesList[0].payAmount
                : 0
            }}
            元；。
          </p>
        </template>
      </div>
      <p>3.2 乙方账户信息：</p>
      <p>名 称： {{ data.supName }}</p>
      <p>账 号：{{ data.account }}</p>
      <p>开户行:{{ data.bankName }}</p>
      <p>
        3.3 经双方协商，合同生效后
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input1"
        />个工作日内乙方开具合规有效发票，甲方见票付款。因乙方延迟提供发票导致甲方未能在预定时间内付款的，不视为甲方延迟付款，甲方不承担由此产生的违约责任。
        发票类型：
        <select v-model.trim="editData.input2" placeholder="请选择发票类型">
          <option value="1">普票</option>
          <option value="2">专票</option>
        </select>
      </p>
      <h4>4.收货日期及条件</h4>
      <p>乙方应当在甲方指定的时间内将合同货物收货至甲方指定的地点。</p>
      <p>
        甲方指定时间:<input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input3"
        />
      </p>
      <h4>5.到货地点</h4>
      <p>
        甲方指定地点：<input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input4"
        />
      </p>
      <p>甲方指定联系人:{{ data.supPerson }}</p>
      <p>联系电话:{{ data.supPhone }}</p>
      <h4>6.包装：</h4>
      <p>
        按行业通用标准执行，无相关标准或规定的，乙方负责采用足以保护产品不受损的包装方式，如因包装质量缺陷而导致的产品损坏，由乙方负责。
      </p>
      <h4>7.运输和保险：</h4>
      <p>由乙方为甲方办理运输和保险，费用由乙方承担。</p>
      <h4>8.验收：</h4>
      <p>
        8.1
        双方同意按照国家标准进行验收，若无国家标准按照双方认可的标准进行验收。乙方交货时，应同时提供相关材料，确保核查、签验工作的顺利进行。直接从国外采购的产品，应附有《海关进口货物报关单》《海关进口货物通关单》《出入境检验检疫证明》等
      </p>
      <p>
        8.2 本产品
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input5"
        />(是/否)包含安装。产品到货后，乙方在
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input6"
        />个工作日内完成安装调试，如产生安装费用由双方另行结算。安装完成或到货后验收由甲乙双方共同进行验收。验收合格的，甲方应及时向供方出具书面验收合格单。验收不合格的，甲方有权拒绝收货，并应于
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input11"
        />
        工作日内向乙方提出书面异议，异议情况属实的，乙方应无条件更换货物
      </p>
      <h4>9.质量保证：</h4>
      <p>
        9.1乙方保证本合同销售的备件为合格产品，符合零备件原厂制造商或授权专业制造商生产工艺方面的要求，没有任何材料和使用上的缺陷，并具有国家认可机构出具的相关质量检验合格文件。
      </p>
      <p>
        9.2对质量缺陷有争议的，甲、乙双方均可委托权威部门进行鉴定，如经鉴定属于乙方责任，则所有鉴定费用由乙方承担，除此之外，乙方须对延迟修复质量缺陷给甲方或第三人造成的全部损失进行赔偿
      </p>
      <p>
        9.3乙方就本合同的零备件提供为期
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input7"
        />
        个月（从验收合格之日起）的保修，此保修条件作为本销售合同中不可分割的一部分。如因乙方提供备件不合格导致换货的，保修期自所换零备件安装使用之日起重新起算。如换货或维修时间超出合理期限或乙方怠于履行保修期内保修义务，给甲方造成损失的，甲方有权追究乙方的赔偿责任。如乙方所承诺的保修期，低于本合同签订时国家强制性法律法规或相关行业中所定的最低质量保证期限，则乙方的保修责任按照国家强制性法律法规或相关行业中所规定的最低质量保证期限执行。
      </p>
      <p>
        9.4 本产品用于
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input8"
        />，如因乙方原因造成本产品用途不符合或不满足，甲方有权要求退货并要求乙方退还已支付款。
      </p>
      <h4>10.信息保密：</h4>
      <p>
        甲乙双方对本合同及本合同相关的其他信息、涉及乙方知识产权的相关信息均负有保密义务。未经另一方事先书面同意，任何一方不得将该信息的任何部分向任何第三方或社会公众披露。
      </p>
      <h4>11.违约责任：</h4>
      <p>
        11.1如乙方逾期收货超过
        <input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input9"
        />天的，甲方有权解除合同，且有权要求乙方退还已付款项，若由此给甲方造成直接损失的，甲方有权要求乙方承担相应的赔偿责任。
      </p>
      <p>
        11.2如甲方逾期支付合同款项超过<input
          class="edit"
          :readonly="readonly"
          v-model.trim="editData.input10"
        />天的，乙方有权解除合同，给乙方造成直接损失的，甲方须承担相应的赔偿责任。
      </p>
      <p>
        11.3保修期内，若乙方未能保证产品质量而导致产生的额外费用由乙方承担，如有质保金的，甲方有权在质保金中先行抵扣。如由此给甲方造成损失的，由乙方承担责任。
      </p>
      <p>
        11.4
        如甲乙双方任何一方存在其他违约，均须承担相应的违约责任，并负责赔偿对方因此造成的经济损失。
      </p>
      <h4>12.反商业贿赂：</h4>
      <p>
        12.1双方应遵守国家及地方现行有效的法律法规，包括但不限于《反不正当竞争法》及国家工商行政管理局颁发的《关于禁止商业贿赂行为的暂行规定》，不得从事任何有损产品或乙方商业形象的行为，并且应遵守下列各项规定。
      </p>
      <p>
        12.2任何一方均应保留能够证明其遵守本条规定的真实准确的记录，经对方要求，应详细说明遵守本条规定的情况。
      </p>
      <p>
        12.3若由于任何一方违反本协议关于反商业贿赂的约定而给对方造成任何损失，包括但不限于商誉损失、政府调查、罚款等，该方应对对方承担赔偿责任。
      </p>
      <h4>13.协议解除：</h4>
      <p>13.1 双方协商一致可解除协议</p>
      <p>13.2 在下列情况下有解除权的一方可以解除协议：</p>
      <p>
        一方延迟履行主要责任，经催告后在协议期限内仍未履行，对方可以解除协议；
      </p>
      <p>
        一方延迟履行债务或者有其他违约行为致使不能实现协议目的，对方可以解除协议；
      </p>
      <p>
        乙方提供的货物出现质量问题，给甲方造成经济损失的，甲方有权解除本协议，并要求乙方赔偿损失。
      </p>
      <h4>14.不可抗力</h4>
      <p>
        甲乙任何一方由于不可抗力原因不能履行协议时，应于事发后五日内向对方通报不能履行或者不能完全履行的理由，以减轻可能给对方造成的损失；在取得有关机构证明后，允许延期履行、部分履行或不履行协议，并根据情况可部分或全部免予承担违约责任。
      </p>
      <h4>15.争议解决及法律适用：</h4>
      <p>
        双方应本着友好协商的原则解决争议。如协商不成，应提交甲方主要经营地法院诉讼解决。
      </p>
      <table>
        <tr>
          <td>甲 方：(盖章)</td>
          <td></td>
          <td>乙 方：(盖章)</td>
          <td></td>
        </tr>
        <tr>
          <td>授权代表：(签字)</td>
          <td></td>
          <td>授权代表：（签字</td>
          <td></td>
        </tr>
        <tr>
          <td>日 期：</td>
          <td></td>
          <td>日 期：</td>
          <td></td>
        </tr>
      </table>
    </div>
  </el-dialog>
</template>
<script>
import { numberToChinese } from '@/utils/number.js';

export default {
  name: 'sale_contract_template',
  props: {
    value: {
      type: Boolean,
    },
    supplierInfo: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => {},
    },
    materialList: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      numberToChinese,
      buyerInfo: {
        name: '上海小紫医疗科技有限公司',
        address: '上海市普陀区金沙江路1340弄172支弄14号3幢3层西侧D座',
        owner: '李振成',
        tax: '',
        postCode: '',
      },
      dialogVisible: this.value,
      editData: {
        input1: '',
        input2: '',
        input3: '',
        input4: '/',
        input5: '/',
        input6: '',
        input7: '/',
        input8: '/',
        input9: '/',
        input10: '/',
        input11: '/',
      },
    };
  },
  watch: {
    value () {
      this.dialogVisible = this.value;
    },
    data () {
      if (this.data.supplyContractContext) {
        this.editData = JSON.parse(this.data.supplyContractContext);
      }
    },
  },
  computed: {
    totalAmount () {
      if (this.data.totalAmount) {
        return this.data.totalAmount.toFixed(2);
      } else {
        return this.materialList
          .reduce((pre, next) => {
            return pre + next.orderNum * next.unitPrice;
          }, 0)
          .toFixed(2);
      }
    },
  },
  methods: {
    handleClose () {
      this.dialogVisible = false;
      this.$emit('input', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.sys_contract_tpl {
  max-height: 600px;
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  td {
    width: 25%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    line-height: 36px;
    height: 36px;
  }
  p {
    text-indent: 2em;
  }

  input.edit {
    display: inline-block;
    width: 100px;
    border: none;
    border-bottom: 1px solid black;
    text-align: center;
  }
}
</style>
