<template>
  <div class="sale-contract-detail">
    <content-block title="基本信息">
      <el-descriptions :column="3" size="normal">
        <el-descriptions-item label="客户抬头">{{
          detailInfo.unit | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="合同生效日期">{{
          detailInfo.effectiveDate | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="合同终止日期">{{
          detailInfo.dueDate | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="签订日期">{{
          detailInfo.signDate | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="签订地点">{{
          detailInfo.signAddress | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="结算方式">{{
          detailInfo.payWayName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="是否含税">{{
          detailInfo.isTaxName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="客户联系人">{{
          detailInfo.salesmanName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人电话">{{
          detailInfo.salesmanPhone | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="联系人邮箱">{{
          detailInfo.email | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="type === 'detail'" label="审核意见">{{
          detailInfo.supIdea | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="type === 'detail'" label="合同状态">{{
          detailInfo.stateName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="type === 'detail'" label="变更状态">{{
          detailInfo.changeStateName | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item v-if="type === 'detail'" label="变更意见">{{
          detailInfo.supChangeIdea | setEmpty
        }}</el-descriptions-item>
        <el-descriptions-item label="备注">{{
          detailInfo.remark | setEmpty
        }}</el-descriptions-item>
      </el-descriptions>
    </content-block>
    <content-block title="物资信息">
      <vxe-grid
        ref="xGrid"
        auto-resize
        resizable
        border
        align="center"
        highlight-current-row
        highlight-hover-row
        show-overflow
        show-footer
        :columns="suppliesColumns"
        :data="materialList"
        :footer-method="footerMethod"
        :merge-footer-items="footerItems"
      >
      </vxe-grid>
    </content-block>
    <content-block title="合同附件信息">
      <el-link
        type="primary"
        v-if="detailInfo.isTemplate === 1"
        @click="showPrint('contract')"
      >
        {{ detailInfo.templateName }}
      </el-link>
      <el-link
        type="primary"
        v-if="detailInfo.isTemplate === 3"
        @click="showPrint('standard')"
        >查看合同附件</el-link
      >
      <upload
        v-if="detailInfo.isTemplate === 2"
        readonly
        v-model.trim="attchList"
        label="标准协议"
        :limit="5"
        :max-size="50"
        size="small"
      />
    </content-block>
    <content-block title="日志信息">
      <TableWithPagination :columnList="logColumns" :tableData="logData" />
    </content-block>
    <sys-template
      v-model.trim="agreementDialogVisible"
      :readonly="true"
      :data="detailInfo"
      :supplierInfo="supplierInfo"
      :materialList="materialList"
    />
    <bottom-panel v-if="type !== 'detail'">
      <label style="float: left">
        <span class="common-font">审批意见：</span>
        <el-input
          placeholder="请输入审批意见"
          v-model.trim="approveMsg"
          style="width: 500px"
          :maxlength="50"
        ></el-input>
      </label>
      <template>
        <el-button
          style="margin-left: 1em"
          type="danger"
          @click="doApprove('reject')"
          >驳回</el-button
        >
        <el-button type="primary" @click="doApprove('pass')">通过</el-button>
      </template>
    </bottom-panel>
    <el-dialog
      title="标准合同附件"
      center
      :visible.sync="printDialogVisible"
      width="50%"
    >
      <contract-tmpl
        class="content-block"
        :basicInfo="printBasicInfo"
        :buyerInfo="printBuyerInfo"
        :goodsList="printGoodsList"
        :printerVisible.sync="printDialogVisible"
        :customerAddress="customerAddress"
        :terms="terms"
        :isIncludeWarranty="false"
        totalPriceKey="unitPriceTaxRate"
        flag="scm"
      />
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TableWithPagination, {
  TablePaginationMixin,
} from '@/components/common/TableWithPagination';
import {
  queryDetail,
  queryLogListById,
  editCheckState,
  editContractState,
  supplierDetail,
  queryMaterialList,
  detailAccountingUnit,
} from '@/api/saleContract';
import setTableCols from './configs';
import SysTemplate from './sysTemplate.vue';
import BottomPanel from '@/components/common/bottomPanel';
import { logTypes } from '@/model/logTypes';
import Upload from '@/components/common/upload';
import ContractTmpl from '@/components/ContractTmpl';
import { Base } from '@/components/ContractTmpl/base';

export default {
  name: 'sale_contract_detail',
  mixins: [TablePaginationMixin],
  components: {
    TableWithPagination,
    BottomPanel,
    SysTemplate,
    Upload,
    ContractTmpl,
  },
  props: {
    id: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  filters: {
    setEmpty (val) {
      return val ? val : '--';
    },
  },
  computed: mapState({
    updateUserId: (state) => state.User.userInfo.userId,
    updateUserName: (state) => state.User.userInfo.name,
  }),
  data () {
    return {
      printDialogVisible: false,
      agreementDialogVisible: false,
      approveMsg: '',
      detailInfo: {},
      materialList: [],
      suppliesColumns: setTableCols.call(this, 'suppliesOfDetail'),
      supplierInfo: {},
      logData: [],
      logColumns: [
        {
          prop: 'createTime',
          label: '时间',
          tooltip: true,
        },
        {
          prop: 'logType',
          label: '事件类型',
          tooltip: true,
          render: (h, { row }) => {
            let val = '--';

            for (let v of logTypes) {
              if (v.value === row.logType) {
                val = v.label;
                break;
              }
            }

            return h('span', val);
          },
        },
        {
          prop: 'content',
          label: '日志内容',
          tooltip: true,
        },
      ],
      footerItems: [{ row: 0, col: 1, rowspan: 1, colspan: 10 }],
      states: [
        '草稿',
        '待审核',
        '生效驳回',
        '执行中',
        '已完结',
        '已取消',
        '待供应商生效确认',
        '供应商生效驳回',
      ],
      attchList: [],
      printBasicInfo: {},
      printGoodsList: [],
      printBuyerInfo: {},
      customerAddress: Base.address,
      terms: [],
      templateList: [],
    };
  },
  created () {
    this.getDetail();
    this.getLog();
  },
  methods: {
    showPrint (type) {
      if (type === 'standard') {
        this.agreementDialogVisible = true;
      } else {
        this.printDialogVisible = true;
      }
    },
    getDetail () {
      queryDetail({ id: this.id }).then(({ body }) => {
        body.newContract.isTaxName = body.newContract.isTax === 1 ? '是' : '否';
        body.newContract.stateName = this.states[body.newContract.state] || '';
        body.newContract.changeStateName
          = body.newContract.changeState === 1
            ? '供应商变更确认'
            : body.newContract.changeState === 2
              ? '供应商变更驳回'
              : body.newContract.changeState === '3'
                ? '已驳回'
                : '';
        body.newContract.payWayName = ['不限', '预付款', '账期付款', '背靠背'][body.newContract.payWay] || '';

        this.detailInfo = body.newContract;

        this.getMaterialList();

        if (this.detailInfo.isTemplate === 2) {
          this.attchList = this.detailInfo.annexList.map((t) => ({
            id: t.id,
            url: t.annexUrl,
            attachId: t.id,
            attachName: t.annexName,
          }));
        } else if (this.detailInfo.annexType === 1) {
          if (this.detailInfo.templateInfo) {
            this.templateList = this.detailInfo.templateInfo.termsList || [];
            this.detailInfo.templateId = this.detailInfo.templateInfo.id;
            this.detailInfo.templateName = this.detailInfo.templateInfo.templateName;

            this.terms = this.templateList.map(v => `${v.title}:${v.content}`);
          }
        }
        this.getDetailAccountingUnit(this.detailInfo.unitId);
        this.getCustomerDetail(this.detailInfo.supId);
      });
    },
    getMaterialList () {
      queryMaterialList({
        id: this.id,
        flag: 2,
        enable: false,
      }).then(({ body }) => {
        this.materialList = body.list;

        if (this.detailInfo.isTemplate !== 2) {
          this.printGoodsList = body.list.map(v => {
            let taxRate = '--';

            if (this.detailInfo.isTax) {
              taxRate = this.detailInfo.taxRate;
            }

            return {
              goodsName: v.goodsName,
              spec: v.specsNo,
              manufacturer: v.producer,
              productUnit: v.unitOfMsment,
              brandName: v.brandName,
              salesVolume: v.orderNum,
              unitPrice: v.unitPrice,
              unitPriceTaxRate: taxRate,
              money: (v.unitPrice * v.orderNum).toFixed(2),
            };
          });
        }

        console.log(this.printGoodsList);
      });
    },
    getCustomerDetail (customerId) {
      supplierDetail({
        id: customerId,
        findContactPersonFlag: true,
        findBankFlag: true,
        findReceiverFlag: true,
      }).then((res) => {
        this.detailInfo.email = res.body.email;
        this.supplierInfo = res.body;

        if (this.detailInfo.isTemplate === 1 && this.detailInfo.annexType === 1) {
          const { body } = res;

          this.printBuyerInfo = {
            name: body.name,
            address:
              `${body.provinceName || ''}${body.cityName
                || ''}${body.areaName || ''}${body.detailAddress || ''}` || '--',
            phone: body.telephone || '--',
            fax: body.fax || '--',
            owner: this.detailInfo.supPerson,
            contactPhone: this.detailInfo.supPhone,
          };
        }
      });
    },
    getLog () {
      queryLogListById({
        id: this.id,
      }).then(({ body }) => {
        this.logData = body;
      });
    },
    footerMethod ({ data }) {
      if (!data.length) {
        return [];
      }

      let totalAmount = 0;
      let totalPrice = 0;

      data.forEach((v) => {
        totalAmount += Number(v.orderNum);
        totalPrice += Number(v.unitPrice * 100 * v.orderNum);
      });

      return [['合计', `数量：${totalAmount}, 总金额：${totalPrice / 100}`]];
    },
    doApprove (type) {
      let api = null;

      let param = {
        id: this.id,
        supIdea: this.approveMsg,
        updateUserId: this.updateUserId,
        updateUserName: this.updateUserName,
      };

      let msg = '';

      if (this.type === 'review') {
        api = editContractState;

        param.state = type === 'reject' ? 7 : 3; // 7->供应商生效驳回； 3->执行中

        msg = type === 'reject' ? '合同生效已驳回' : '审核通过';
      } else if (this.type === 'change') {
        api = editCheckState;

        param.state = type === 'reject' ? 2 : 3; // 2->变更已驳回； 3->已变更
        msg = type === 'reject' ? '合同变更已驳回' : '审核通过';
      }

      api(param).then(() => {
        this.$message({
          message: msg,
          type: 'success',
        });

        this.$router.back();
      });
    },
    getDetailAccountingUnit (id) {
      detailAccountingUnit({id: id}).then(({body}) => {
        this.printBasicInfo = {
          code: this.detailInfo.pcCode,
          signedDate: new Date(this.detailInfo.signDate).valueOf(),
          customerHead: body.accountName || '--',
          contactPhone: this.detailInfo.salesmanPhone || '--',
          fax: body.fax || '--',
          customerContact: this.detailInfo.salesmanName || '--',
          detailAddress: `${body.provinceName}${body.cityName}${body.areaName}${body.address}`,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.sale-contract-detail {
  .common-font {
    color: #909399;
    font-size: 14px;
    font-weight: 500;
  }

  .annex {
    margin-right: 1em;
  }
}
</style>
