<template>
  <div class="sys_contract_tpl">
    <h4>合同编号: {{ basicInfo.code }}</h4>
    <content-block>
      <table>
        <tr>
          <td>供方</td>
          <td>{{ buyerInfo.name }}</td>
          <td>签订日期</td>
          <td>{{ formatDate(basicInfo.signedDate, "YYYY-MM-DD") }}</td>
        </tr>
        <tr>
          <td>地址</td>
          <td>{{ buyerInfo.address }}</td>
          <td>购方</td>
          <td>{{ basicInfo.customerHead }}</td>
        </tr>
        <tr>
          <td>电话</td>
          <td>{{ buyerInfo.phone }}</td>
          <td>地址</td>
          <td>
            {{basicInfo.detailAddress || customerAddr}}
          </td>
        </tr>
        <tr>
          <td>传真</td>
          <td>{{ buyerInfo.fax }}</td>
          <td>电话</td>
          <td>{{ basicInfo.contactPhone }}</td>
        </tr>
        <tr>
          <td>联系人</td>
          <td>{{ buyerInfo.owner }}</td>
          <td>传真</td>
          <td>{{ basicInfo.fax || "--" }}</td>
        </tr>
        <tr>
          <td>联系电话</td>
          <td>{{ buyerInfo.contactPhone }}</td>
          <td>订货人</td>
          <td>{{ basicInfo.customerContact }}</td>
        </tr>
      </table>
    </content-block>
    <content-block>
      <vxe-table
        border
        auto-resize
        resizable
        highlight-hover-row
        show-header-overflow
        show-overflow
        show-footer
        align="center"
        :merge-footer-items="mergeFooterItems"
        :footer-method="footerMethod"
        :data="goodsList"
      >
        <vxe-table-column field="goodsName" title="商品名称"></vxe-table-column>
        <vxe-table-column field="spec" title="规格型号"></vxe-table-column>
        <vxe-table-column
          field="manufacturer"
          title="生产商"
        ></vxe-table-column>
        <vxe-table-column
          field="productUnit"
          title="计量单位"
        ></vxe-table-column>
        <vxe-table-column field="brandName" title="品牌名称"></vxe-table-column>
        <vxe-table-column v-if="isIncludeWarranty" field="warranty" title="质保期"></vxe-table-column>
        <vxe-table-column field="salesVolume" title="数量"></vxe-table-column>
        <vxe-table-column field="unitPrice" title="含税单价"></vxe-table-column>
        <vxe-table-column
          field="unitPriceTaxRate"
          title="税率"
        ></vxe-table-column>
        <vxe-table-column field="money" title="金额"></vxe-table-column>
      </vxe-table>
    </content-block>
    <content-block>
      <p v-for="(v, i) in agreements" :key="v">
        {{ `${toChinesNum(i + 1)}、${v}` }}
      </p>
    </content-block>
    <content-block>
      <table>
        <tr>
          <td>供 方：(盖章)</td>
          <td></td>
          <td>购 方：(盖章)</td>
          <td></td>
        </tr>
        <tr>
          <td>日 期：</td>
          <td></td>
          <td>日 期：</td>
          <td></td>
        </tr>
      </table>
    </content-block>
    <el-row type="flex" justify="center">
      <div v-if="printerInfo.isPrint" class="print-time">
        {{ `打印次数： ${this.printerInfo.printNum}` }}
      </div>
      <el-button
        v-if="printerInfo.isPrint"
        type="primary"
        size="small"
        @click="handlePrint"
        >打印</el-button
      >
      <el-button v-if="printerInfo.isPrint" size="small" @click="cancel"
        >取消</el-button
      >
    </el-row>
  </div>
</template>
<script>
import * as Moment from 'dayjs';
import { toChinesNum } from '@/utils/number.js';
import { Base } from './base';

export default {
  name: 'contract_template',
  props: {
    basicInfo: {
      type: Object,
      default: () => {},
    },
    goodsList: {
      type: Array,
      default: () => [],
    },
    printerVisible: {
      type: Boolean,
      default: false,
    },
    printerInfo: {
      type: Object,
      default: () => ({
        isPrint: false,
        printId: '',
        printNum: 0,
      }),
    },
    buyerInfo: {
      type: Object,
      default: () => Base,
    },
    customerAddress: {
      type: String,
      default: '',
    },
    terms: {
      type: Array,
      default: () => ([
        '以上价款含税费、保险费、运费',
        '付款方式：①购方支付首笔款项为订货总金额 100 %',
        '以汇款方式付款的汇到供方指定帐号。发票开具：专票',
        '发货方式： 快递 ， 发货时间：款到后3个工作日到货地点： 约定地点',
        '本单经供方盖章后回传(传真或双方认可的电子文本不受限制)，购方支付首笔款项后开始生效。产生争议由购方所在地人民法院管辖',
      ]),
    },
    isIncludeWarranty: {
      type: Boolean,
      default: true,
    },
    flag: {
      type: String,
      default: 'oms',
    },
    totalPriceKey: {
      type: String,
      default: 'unitPrice',
    },
  },
  data () {
    return {
      mergeFooterItems: [
        { row: 0, col: 1, rowspan: 1, colspan: 5 },
        { row: 0, col: 7, rowspan: 1, colspan: 3 },
      ],
      toChinesNum,
      agreements: [...this.terms],
      customerAddr: this.customerAddress,
    };
  },
  created () {
    /*
    this.getTemplateDetail();
    this.getReceiverList();
    */
  },
  methods: {
    cancel () {
      this.$emit('update:printerVisible', false);
    },
    formatDate (time, formatter) {
      return Moment(time).format(formatter);
    },
    footerMethod ({ columns }) {
      const footerData = [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '数量合计';
          }

          if (column.property === 'spec') {
            return this.goodsList
              .reduce((pre, next) => {
                return pre + next.salesVolume * 1;
              }, 0)
              .toFixed(2);
          }

          if (columnIndex === 6) {
            return '总金额';
          }

          if (this.totalPriceKey === column.property) {
            return this.goodsList
              .reduce((pre, next) => {
                return pre + next.money * 1;
              }, 0)
              .toFixed(2);
          }

          return null;
        }),
      ];

      return footerData;
    },
    /*
    getTemplateDetail () {
      if (this.basicInfo.templateId) {
        SaleTemplateApi.getDetail(this.basicInfo.templateId).then((res) => {
          this.agreements = (res.body.clauses || []).map(
            (t) => `${t.articleTitle}：${t.contentTerms}`,
          );
        });
      }
    },
    getReceiverList () {
      customerDetail({
        id: this.basicInfo.customerId,
      }).then(({ body }) => {
        if (body) {
          this.customerAddr
            = `${body.provinceName || ''}${body.cityName || ''}${
              body.areaName || ''
            }${body.detailAddress || ''}` || '--';
        }
      });
    },
    handlePrint () {
      if (this.printerInfo.printId) {
        const api = this.flag === 'oms' ? printContract : ContractApi.editPrintNum;

        api({
          id: this.printerInfo.printId,
        });
      }

      this.cancel();

      const flag = this.isIncludeWarranty ? ENUMS.SALE : ENUMS.PURCHASE;

      const printData = getSetting(flag);

      let hiprintTemplate = new hiprint.PrintTemplate({
        template: printData,
      });

      let termsContent = '';

      this.agreements.forEach((v, index) => {
        termsContent += `${toChinesNum(index + 1)}、${v}\n`;
      });

      let data = {
        code: this.basicInfo.code,
        name: this.buyerInfo.name,
        signedDate: this.formatDate(this.basicInfo.signedDate, 'YYYY-MM-DD'),
        address: this.buyerInfo.address,
        customerHead: this.basicInfo.customerHead,
        phone: this.buyerInfo.phone,
        customerAddress: this.customerAddr,
        fax: this.buyerInfo.fax,
        contactPhone: this.basicInfo.contactPhone,
        owner: this.buyerInfo.owner,
        contactFax: this.basicInfo.fax || '--',
        buyerContactPhone: this.buyerInfo.contactPhone,
        customerContact: this.basicInfo.customerContact,
        goodsList: this.goodsList,
        termsContent,
      };

      hiprintTemplate.print(data);
    },*/
  },
};
</script>
<style lang='scss' scoped>
.sys_contract_tpl {
  overflow: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    border-left: 1px solid black;
    border-bottom: 1px solid black;
  }
  td {
    width: 25%;
    border-top: 1px solid black;
    border-right: 1px solid black;
    text-align: center;
    line-height: 36px;
    height: 36px;
  }
  h4 {
    padding-left: 12px;
  }

  .print-time {
    position: absolute;
    left: 0;
    padding-left: 12px;
  }
}
</style>
